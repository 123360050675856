import React, { lazy } from "react";
import executor from "common/utils/executor";
import AuthGuard from "guards/auth.guard";
import OrganizationGuard from "guards/organization.guard";
import Checkout from "./pages/Settings/Billing/Checkout";
import CheckoutFinished from "./pages/Settings/Billing/Checkout/CheckoutFinished";
import ConsentGuard from "./guards/consent.guard";
import OpenCheckout from "./pages/Settings/Billing/Checkout/OpenCheckout";

// const Editor = lazy(() => import("./pages/Editor"));
const Auth = lazy(() => import("./pages/Auth"));
const Organization = lazy(() => import("./pages/Organization"));
const SetupOrg = lazy(() => import("./pages/SetupOrg"));
const ChooseOrg = lazy(() => import("./pages/ChooseOrg"));
const Profile = lazy(() => import("./pages/Profile"));
const EmailUpdateConfirmation = lazy(() => import("./pages/Profile/ProfileSettings/EmailUpdated"));
const PreviewImage = lazy(() => import("./pages/Preview"));
const Redirection = lazy(() => import("./pages/Redirection"));
const AuthRedirection = lazy(() => import("./pages/AuthRedirection"));
const OpenAiAuth = lazy(() => import("./pages/Connect/OpenAI"));
const ChromeExt = lazy(() => import("./pages/Connect/ChromeExt"));
const UserInfo = lazy(() => import("./pages/UserInfo"));
const ConsentConfirmation = lazy(() => import("./pages/ConsentConfirmation"));

const routes = [
    {
        path: "/auth",
        component: Auth,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/userinfo",
        component: UserInfo,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/choose-org",
        component: ChooseOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/setup-org",
        component: SetupOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/profile",
        component: Profile,
        guard: () => {
            return executor([AuthGuard, ConsentGuard]);
        },
    },
    {
        path: "/consent-approval",
        component: ConsentConfirmation,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/checkout",
        component: OpenCheckout,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/checkout",
        component: OpenCheckout,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    // {
    //     path: "/organization/:orgId/billing",
    //     component: Billing,
    //     guard: () => {
    //         return executor([AuthGuard, OrganizationGuard]);
    //     },
    // },
    {
        path: "/organization/:orgId/settings/billing/checkout/finish",
        component: CheckoutFinished,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId/settings/billing/checkout",
        component: Checkout,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard, ConsentGuard]);
        },
    },
    {
        path: "/organization/:orgId/preview/:fileId*",
        component: PreviewImage,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/organization/:orgId",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard, ConsentGuard]);
        },
    },
    // {
    //     path: "/editor/:id?",
    //     component: Editor,
    //     guard: () => {
    //         return executor([]);
    //     },
    // },
    {
        path: "/email-update",
        component: EmailUpdateConfirmation,
        guard: () => {
            return executor([]);
        },
    },
    {
        path: "/connect/openai/auth",
        component: OpenAiAuth,
        guard: () => {
            return executor([]);
        },
    },
    {
        path: "/connect/chrome-ext",
        component: ChromeExt,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/redirect",
        component: Redirection,
        guard: () => {
            return executor([]);
        },
    },
    {
        path: "/auth-redirect",
        component: AuthRedirection,
        guard: () => {
            return executor([]);
        },
    },
    {
        path: "*",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard, ConsentGuard]);
        },
    },
];

export default routes;
