import AuthService from "../../../services/auth.service";
import { OrgAction } from "./orgActions";
import _ from "lodash";
import { getPublicPropAnonymousId, resetSegmentUserId } from "../../utils";
import environment from "../../../environment";
import { ANONYMOUS_ID_PARAM_KEY, SEGMENT_ANONYMOUS_ID_KEY } from "../../constants/constants";
import { setLoaderOverlayVisiblity } from "./menuActions";
export const AuthAction = {
    RegisterSuccess: "REGISTER_SUCCESS",
    RegisterFailed: "REGISTER_FAIL",
    LoginSuccess: "LOGIN_SUCCESS",
    LoginFailed: "LOGIN_FAIL",
    Logout: "LOGOUT",
    CheckUserSession: "CHECK_USER_SESSION",
    GoogleLoginSuccess: "GOOGLE_LOGIN_SUCCESS",
    GoogleLoginFailed: "GOOGLE_LOGIN_FAILED",
    UpdateDetailsSuccess: "UPDATE_DETAILS_SUCCESS",
    UpdateDetailsFailed: "UPDATE_DETAILS_FAILED",
    UpdateUserMetaSuccess: "UPDATE_USER_META_SUCCESS",
    UpdateUserMetaFailed: "UPDATE_USER_META_FAILED",
    SetRedirectUrl: "SET_REDIRECT_URL",
    ClearRedirectUrl: "CLEAR_REDIRECT_URL",
};

export const register = (formData, stateSearchParam, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.register(formData, stateSearchParam)
            .then((res) => {
                dispatch({
                    type: AuthAction.RegisterSuccess,
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.RegisterFailed,
                });
                reject(err);
            });
    });
};

export const login = (formData, dispatch, callbackFn = () => {}) => {
    return new Promise((resolve, reject) => {
        AuthService.login(formData)
            .then((res) => {
                callbackFn();
                dispatch({
                    type: AuthAction.LoginSuccess,
                    payload: { isUserLoggedIn: true, user: res.data.user },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.LoginFailed,
                });
                reject(err);
            });
    });
};

const redirectTo = (source, anonymousId) => {
    switch (source) {
        case "erasebg":
            window.open(`${environment.SAKURA_MAIN_DOMAIN}`, "_self");
            break;

        case "upscalemedia":
            window.open(`${environment.SARADA_MAIN_DOMAIN}`, "_self");
            break;

        case "watermarkremover":
            window.open(`${environment.SUIGETSU_MAIN_DOMAIN}`, "_self");
            break;

        case "glamar":
            window.open(`${environment.KIBA_MAIN_DOMAIN}`, "_self");
            break;

        case "shrinkmedia":
            window.open(`${environment.SAI_MAIN_DOMAIN}`, "_self");
            break;

        case "convertfiles":
            window.open(`${environment.SATETSU_MAIN_DOMAIN}`, "_self");
            break;
        default:
            window.location.assign(
                `${environment.HINATA_MAIN_DOMAIN}?${ANONYMOUS_ID_PARAM_KEY}=${anonymousId}`,
            );
            break;
    }
};

export const logout = (dispatch) => {
    setLoaderOverlayVisiblity(true, dispatch);
    return new Promise((resolve, reject) => {
        AuthService.logout()
            .then((res) => {
                dispatch({
                    type: AuthAction.Logout,
                    payload: {
                        isUserLoggedIn: false,
                        user: null,
                    },
                });
                dispatch({
                    type: OrgAction.Logout,
                    payload: {
                        isOrgRegistered: false,
                        organization: null,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.Logout,
                    payload: {
                        isUserLoggedIn: false,
                        user: null,
                    },
                });
                reject(err);
            })
            .finally(() => {
                const anonymousId = getPublicPropAnonymousId();
                const currentUrl = new URL(window.location.href);
                const queryParams = currentUrl.searchParams;
                resetSegmentUserId();
                if (queryParams.has("ref")) {
                    window.location.assign(
                        `${environment.HINATA_MAIN_DOMAIN}?${ANONYMOUS_ID_PARAM_KEY}=${anonymousId}`,
                    );
                } else if (
                    sessionStorage.getItem("widgetRedirectUrl") &&
                    sessionStorage.getItem("isWidget")
                ) {
                    window.location.assign(sessionStorage.getItem("widgetRedirectUrl"));
                } else {
                    const source = sessionStorage.getItem("utm_source");
                    redirectTo(source, anonymousId);
                }
                sessionStorage.removeItem("utm_source");
            });
    });
};

/* export const checkUserSession = (dispatch) => {
  return new Promise((resolve, reject) => {
    UserService.getCurrentUserSession()
      .then((res) => {
        dispatch({
          type: AuthAction.CheckUserSession,
          payload: {
            isUserLoggedIn: true,
            user: res.data.session.passport.user,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: AuthAction.CheckUserSession,
          payload: {
            isUserLoggedIn: false,
            user: null,
          },
        });
        reject(err);
      });
  });
}; */

export const googleLogin = (userInfo, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.loginViaGoogle(userInfo)
            .then((res) => {
                dispatch({
                    type: AuthAction.GoogleLoginSuccess,
                    payload: {
                        isUserLoggedIn: true,
                        user: res.data.user,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.GoogleLoginSuccess,
                    payload: {
                        isUserLoggedIn: false,
                        user: null,
                    },
                });
                reject(err);
            });
    });
};

export const updateProfile = (updateOpts, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.updateProfile(updateOpts)
            .then((res) => {
                dispatch({
                    type: AuthAction.UpdateDetailsSuccess,
                    payload: {
                        isUserLoggedIn: true,
                        user: res.data.user,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.UpdateDetailsFailed,
                    payload: {
                        isUserLoggedIn: true,
                    },
                });
                reject(err);
            });
    });
};

export const updateProfileMeta = (metaOpts, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.updateMeta(metaOpts)
            .then((res) => {
                dispatch({
                    type: AuthAction.UpdateUserMetaSuccess,
                    payload: {
                        user: res.data.user,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.UpdateDetailsFailed,
                });
                reject(err);
            });
    });
};

export const setRedirectUrl = (data, dispatch) => {
    dispatch({
        type: AuthAction.SetRedirectUrl,
        payload: data,
    });
};

export const clearRedirectUrl = (dispatch) => {
    dispatch({
        type: AuthAction.ClearRedirectUrl,
        payload: {
            redirectUrl: null,
        },
    });
};

export const setUserSession = (data, dispatch) => {
    dispatch({
        type: AuthAction.CheckUserSession,
        payload: {
            isUserLoggedIn: true,
            user: data,
        },
    });
};
