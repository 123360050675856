import URLS from "common/utils/apiUrls";
import ApiService from "./api.service";

const PaymentsService = {
    getInvoices(orgId, searchOpts = {}, pageNo = 1, pageSize = 10) {
        const params = {
            ...searchOpts,
            pageNo,
            pageSize,
        };
        return ApiService.get(URLS.invoices.replace(":orgId", orgId), { params });
    },

    getInvoice(orgId, invoiceId, includePgDetails = true) {
        const params = {
            includePgDetails,
        };
        return ApiService.get(URLS.pgInvoice.replace(":orgId", orgId).replace(":pgId", invoiceId), {
            params,
        });
    },

    getInvoiceWithoutOrg(invoiceId) {
        return ApiService.get(URLS.pgInvoiceOpen.replace(":pgId", invoiceId));
    },

    getRefunds(orgId, searchOpts = {}, pageNo = 1, pageSize = 10) {
        const params = {
            ...searchOpts,
            pageNo,
            pageSize,
        };
        return ApiService.get(URLS.refunds.replace(":orgId", orgId), { params });
    },

    getActiveSubscription(orgId) {
        return ApiService.get(`${URLS.activeSubscription}`.replace(":orgId", orgId), {});
    },

    getUpcomingSubscription(orgId) {
        return ApiService.get(`${URLS.upcomingSubscription}`.replace(":orgId", orgId), {});
    },

    getPlan(planId) {
        return ApiService.get(`${URLS.plan}`.replace(":id", planId));
    },

    getPlans(searchOpts = {}, pageNo = 1, pageSize = 20) {
        const params = {
            ...searchOpts,
            pageNo,
            pageSize,
        };
        return ApiService.get(URLS.plans, {
            params,
        });
    },

    getAddon(addonId) {
        return ApiService.get(`${URLS.addOn}`.replace(":id", addonId));
    },

    getAddOns(searchOpts = {}, pageNo = 1, pageSize = 20) {
        const params = {
            ...searchOpts,
            pageNo,
            pageSize,
        };
        return ApiService.get(URLS.addOns, {
            params,
        });
    },

    initCard(orgId, data) {
        return ApiService.post(`${URLS.initCard}`.replace(":orgId", orgId), { data });
    },

    saveCard(orgId, data) {
        return ApiService.post(`${URLS.cards}`.replace(":orgId", orgId), { data });
    },

    updateCard(orgId, paymentMethodId, data) {
        return ApiService.patch(
            `${URLS.updateCards}`
                .replace(":orgId", orgId)
                .replace(":paymentMethodId", paymentMethodId),
            { data },
        );
    },

    deleteCard(orgId, paymentMethodId) {
        return ApiService.delete(
            `${URLS.updateCards}`
                .replace(":orgId", orgId)
                .replace(":paymentMethodId", paymentMethodId),
        );
    },

    getCards(orgId, queryString) {
        const url = queryString ? `${URLS.cards}?${queryString}` : URLS.cards;
        return ApiService.get(url.replace(":orgId", orgId));
    },

    buySubscription(orgId, data) {
        return ApiService.post(`${URLS.subscriptions}`.replace(":orgId", orgId), { data });
    },

    buyAddOn(orgId, data) {
        return ApiService.post(`${URLS.customerAddOns}`.replace(":orgId", orgId), {
            data,
        });
    },

    cancelSubscription(orgId, data) {
        return ApiService.post(`${URLS.cancelSubscription}`.replace(":orgId", orgId), { data });
    },

    getSubscriptionUsage(orgId) {
        let axiosOptions = {};
        return ApiService.get(URLS.subscriptionUsage.replace(":orgId", orgId), axiosOptions);
    },

    getActiveAddonsUsage(orgId) {
        const params = {
            active: true,
        };
        return ApiService.get(URLS.addonsUsage.replace(":orgId", orgId), { params });
    },

    getTotalUsage(orgId) {
        let axiosOptions = {};
        return ApiService.get(URLS.totalUsage.replace(":orgId", orgId), axiosOptions);
    },

    getTotalUsage(orgId) {
        let axiosOptions = {};
        return ApiService.get(URLS.totalUsage.replace(":orgId", orgId), axiosOptions);
    },

    getCustomerAddOns(orgId, searchOpts = {}, pageNo = 1, pageSize = 20) {
        const params = {
            ...searchOpts,
            pageNo,
            pageSize,
        };
        return ApiService.get(`${URLS.customerAddOns}`.replace(":orgId", orgId), { params });
    },

    getPayments(orgId, searchOpts = {}, pageNo = 1, pageSize = 10) {
        const params = {
            ...searchOpts,
            pageNo,
            pageSize,
        };

        return ApiService.get(URLS.payments.replace(":orgId", orgId), { params });
    },
    saveBillingAddress(orgId, data) {
        return ApiService.post(`${URLS.billingAddress}`.replace(":orgId", orgId), { data });
    },
    getCustomerAddress(orgId) {
        return ApiService.get(URLS.billingAddress.replace(":orgId", orgId));
    },
    updateBillingAddress(orgId, data) {
        return ApiService.patch(URLS.billingAddress.replace(":orgId", orgId), { data });
    },
    getExchangeRates(code) {
        const params = {
            code,
        };
        return ApiService.get(URLS.currency, { params });
    },
    getChangeActionType(orgId, newPlanId) {
        const params = {
            newPlanId,
        };
        return ApiService.get(URLS.getChangeActionType.replace(":orgId", orgId), { params });
    },
};

module.exports = PaymentsService;
