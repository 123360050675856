import React, { memo, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useEscapeKey } from "../../hooks/useEscapeKey";

export const ModalWrapper = styled.div`
    position: fixed;
    padding: 16px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    backdrop-filter: brightness(0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    transition: all 0.3s ease-in-out;
    transform: translateY(-100vh);
    opacity: 0;
    &.active {
        transform: translateY(0);
        opacity: 1;
    }
    background: rgb(16, 17, 18, 0.6);
    ${(props) => props.CustomModalWrapper};
`;

export const ModalContent = styled.div`
    position: relative;
    //   padding: 40px;
    width: 100vw;
    height: auto;
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "900px")};
    border-radius: var(--border-radius-8);

    ${(props) => props.CustomModalContent};
`;

const Modal = memo(
    ({
        children,
        isVisible,
        maxWidth,
        CustomModalWrapper,
        CustomModalContent,
        hide,
        disableEscapeKey,
    }) => {
        const domEl = document.getElementById("modal-root");
        if (!domEl) return null;

        useEffect(() => {
            if (isVisible) document.body.style.overflow = "hidden";
            else document.body.style.overflow = "unset";
        }, [isVisible]);

        useEscapeKey(hide, [!disableEscapeKey]);

        return ReactDOM.createPortal(
            <>
                <ModalWrapper
                    className={isVisible ? "active" : ""}
                    CustomModalWrapper={CustomModalWrapper}
                >
                    <ModalContent maxWidth={maxWidth} CustomModalContent={CustomModalContent}>
                        {children}
                    </ModalContent>
                </ModalWrapper>
            </>,
            domEl,
        );
    },
);

export * from "./useModal";

export default Modal;
