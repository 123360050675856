import { WidgetConfigAction } from "../actions/widgetActions";
import { WidgetEventEmitter } from "@common/events/widgetEventEmitter";

const initialState = {
    isWidget: false,
    mediaLibraryId: "",
    frameHost: "",
    searchConfig: {
        selectMuliple: true,
        selectMax: 5,
    },
    removeHeader: false,
    insertCaption: "Insert",
    widgetEventEmitter: null,
    ml_id: null,
};

function widgetConfigReducer(state = initialState, action) {
    switch (action.type) {
        case WidgetConfigAction.WIDGET_INITIAL_SETUP: {
            const config = {
                ...state,
                ...action.payload,
            };
            const widgetEventEmitter = new WidgetEventEmitter({ config });
            return {
                ...config,
                widgetEventEmitter,
            };
        }
        default:
            return state;
    }
}
export default widgetConfigReducer;
