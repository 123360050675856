import React, { useState } from "react";
import Modal from "./index";
import styled from "styled-components";
import { ReactComponent as CrossIcon } from "common/assets/svgs/Cross.svg";
import IconButton from "../IconButton";
import { Breakpoints } from "../../../GlobalStyle";

const CancelIconWrapper = styled(IconButton)`
    position: absolute;
    top: 16px;
    right: 16px;

    @media (max-width: ${Breakpoints.mobile}px) {
        top: 8px;
        right: 8px;
    }
`;

export const useModal = () => {
    const [isVisible, setIsVisible] = useState(false);
    const show = () => setIsVisible(true);
    const hide = () => setIsVisible(false);

    const RenderModal = ({
        children,
        maxWidth,
        showCancelButton = true,
        CustomModalWrapper,
        CustomModalContent,
        cancelButtonProps,
        disableEscapeKey = false,
    }) => (
        <>
            {isVisible && (
                <Modal
                    isVisible={isVisible}
                    maxWidth={maxWidth}
                    CustomModalWrapper={CustomModalWrapper}
                    CustomModalContent={CustomModalContent}
                    hide={hide}
                    disableEscapeKey={disableEscapeKey}
                >
                    {isVisible && children}
                    {showCancelButton && (
                        <CancelIconWrapper {...cancelButtonProps} onClick={hide}>
                            <CrossIcon />
                        </CancelIconWrapper>
                    )}
                </Modal>
            )}
        </>
    );
    return {
        show,
        hide,
        RenderModal,
        isVisible,
    };
};
