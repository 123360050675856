import environment from "@src/environment";
import { countries } from "country-data";

export const COUNTRIES = countries.all
    .filter((country) => country.status === "assigned")
    .map((country) => ({
        label: country.name,
        identifier: country.alpha2,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const COUNTRIES_V2 = countries.all
    .filter((country) => country.status === "assigned")
    .map((country) => ({
        label: country.name,
        value: { code: country.alpha2, name: country.name },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getStatesInCountry = async (countryCode) => {
    const response = await fetch(`${environment.HINATA_MAIN_DOMAIN}/states/${countryCode}`);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data.map((stateName) => {
        return {
            label: stateName,
            value: stateName,
        };
    });
};

export const stripeOptions = {
    style: {
        base: {
            fontSize: "16px",
            color: "#ffffff",
            letterSpacing: "0.025em",
            fontFamily: "Inter, sans-serif",
            "::placeholder": {
                color: "rgba(255, 255, 255, 0.4)",
            },
        },
        invalid: {
            color: "#FF5858",
        },
    },
};

export const MAX_UPLOAD_LIMIT = 21000;
export const MAX_UPLOAD_FILE_SIZE = 300 * 1024 * 1024;
export const MAX_UPLOAD_IMAGE_SIZE = 100 * 1024 * 1024;

export const PRESET_SOURCE = {
    playground: "PLAYGROUND",
    presetPage: "PRESET_PAGE",
};

export const userFieldsForAnalytics = ["_id", "email", "firstName", "lastName"];
export const orgFieldsForAnalytics = ["_id", "name", "cloudName", "ownerId", "active"];
export const newPlaygroundProperties = ["erasebg", "watermarkremover", "upscalemedia", "glamar"];
