import { useState, useEffect } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const getQueryParams = () => {
    try {
        if (typeof window === "undefined") return {};
        const searchParams = new URLSearchParams(window.location.search);
        let paramsObj = {};

        for (let param of searchParams.entries()) {
            paramsObj[param[0]] = param[1];
        }

        return paramsObj;
    } catch (err) {
        console.error(err);
        return {};
    }
};

function getReferrerObject() {
    try {
        if (typeof document === "undefined") return null;
        if (!document.referrer) return null;
        const referrerURL = new URL(document.referrer);

        return {
            protocol: referrerURL.protocol,
            host: referrerURL.host,
            hostname: referrerURL.hostname,
            port: referrerURL.port,
            pathname: referrerURL.pathname,
            search: referrerURL.search,
            hash: referrerURL.hash,
            origin: referrerURL.origin,
        };
    } catch (err) {
        return {};
    }
}

export const INTERNAL_EMAIL_PATTERN =
    /^[a-zA-Z0-9._%+-]+@(?!gofynd\.com|fynd\.com|ril\.com|pixelbin\.io|fynd-external\.com|fyndplatform\.com|clarks\.in)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const useTrackingProperties = () => {
    const history = useHistory();
    const isAndroidApp = useSelector((state) => state.userAgentDetails.isAndroidApp);
    const userDetail = useSelector((state) => state?.authenticationDetails?.user);
    const orgId = useSelector((state) => state?.organizationDetails?.organization?._id);

    const [properties, setProperties] = useState({
        context_locale:
            typeof navigator !== "undefined" ? navigator.language || navigator.userLanguage : null,
        campaign: getQueryParams(),
        referrer: getReferrerObject(),
        org_id: orgId,
        channel: isAndroidApp ? "app" : "browser",
        page_category: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_type: isMobile ? "mobile" : "desktop",

        user_type: INTERNAL_EMAIL_PATTERN.test(userDetail?.email) ? "external" : "internal",
        user_agent: typeof navigator !== "undefined" ? navigator.userAgent : null,
    });

    useEffect(() => {
        // This will run every time the route changes
        const unlisten = history?.listen(() => {
            setProperties((prevProps) => ({
                ...prevProps,
                campaign: getQueryParams(),
                referrer: getReferrerObject(),
            }));
        });

        // Cleanup the listener when the hook/component unmounts
        return () => {
            unlisten?.();
        };
    }, [history]);

    return properties;
};

export default useTrackingProperties;
