import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const ExplorerService = {
    getItems(
        orgId,
        folderId,
        onlyFiles,
        onlyFolders,
        onlyTransformationSupported,
        sortBy,
        sortAscending,
        pageNo = 1,
        pageSize = 100,
    ) {
        let axiosOptions = {
            params: {
                sort: sortAscending ? sortBy : "-" + sortBy,
                onlyFiles,
                onlyFolders,
                onlyTransformationSupported,
                pageNo,
                pageSize,
            },
        };

        // If URL has folderId, get items inside that folder
        // else get items inside root folder
        if (folderId) {
            return ApiService.get(
                `${URLS.exploreFolder}/${folderId}`.replace(":orgId", orgId),
                axiosOptions,
            );
        }

        return ApiService.get(URLS.explore.replace(":orgId", orgId), axiosOptions);
    },

    async searchItems(
        orgId,
        searchOpts,
        sortBy,
        sortAscending,
        filePageNo,
        filePageSize,
        folderPageNo,
        folderPageSize,
        folderId,
        cb,
    ) {
        let files = await ExplorerService.searchFiles(
            orgId,
            searchOpts,
            sortBy,
            sortAscending,
            filePageNo,
            filePageSize,
            folderId,
        );

        let folders = await ExplorerService.searchFolders(
            orgId,
            searchOpts,
            folderPageNo,
            folderPageSize,
            folderId,
        );

        cb({
            files: files.data,
            folders: folders.data,
        });
    },

    async searchFiles(orgId, searchOpts, sortBy, sortAscending, pageNo, pageSize, path) {
        const searchFilters = getFilterProperties(searchOpts);

        let axiosOpts = {
            params: {
                ...searchFilters,
                onlyFiles: true,
                sort: sortAscending ? sortBy : "-" + sortBy,
                pageNo,
                pageSize,
                path,
            },
        };

        return await ApiService.get(URLS.search.replace(":orgId", orgId), axiosOpts);
    },

    async searchFolders(orgId, searchOpts, pageNo, pageSize, path) {
        const searchFilters = getFilterProperties(searchOpts);

        let axiosOpts = {
            params: {
                ...searchFilters,
                onlyFolders: true,
                pageNo,
                pageSize,
                path,
            },
        };

        return await ApiService.get(URLS.search.replace(":orgId", orgId), axiosOpts);
    },

    async searchFilesAndFolder(
        orgId,
        searchString,
        sortBy,
        sortAscending,
        pageNo,
        pageSize,
        folderId,
    ) {
        let axiosOpts = {
            params: {
                ...searchString,
                sort: sortAscending ? sortBy : "-" + sortBy,
                pageNo,
                pageSize,
            },
        };
        if (folderId) {
            return ApiService.get(`${URLS.search}/${folderId}`.replace(":orgId", orgId), axiosOpts);
        }
        return await ApiService.get(`${URLS.search}`.replace(":orgId", orgId), axiosOpts);
    },

    updateFile(orgId, fileId, updateOpts) {
        const axiosOpt = {
            data: updateOpts,
        };
        return ApiService.patch(`${URLS.filesUrl}/${fileId}`.replace(":orgId", orgId), axiosOpt);
    },

    deleteFile(orgId, fileId) {
        return ApiService.delete(`${URLS.filesUrl}/${fileId}`.replace(":orgId", orgId));
    },

    deleteFiles(orgId, fileIds) {
        const axiosOpts = {
            data: {
                ids: fileIds,
            },
        };
        return ApiService.post(`${URLS.filesUrl}/delete`.replace(":orgId", orgId), axiosOpts);
    },

    deleteFolder(orgId, _id) {
        return ApiService.delete(`${URLS.foldersUrl}/${_id}`.replace(":orgId", orgId));
    },

    getAncestors(orgId, _id) {
        return ApiService.get(URLS.ancestors.replace(":orgId", orgId).replace(":folderId", _id));
    },

    getPlugins(orgId) {
        return ApiService.get(URLS.plugins.replace(":orgId", orgId));
    },

    getDefaultPlaygroundUrl(orgId) {
        return ApiService.get(URLS.playgroundDefault.replace(":orgId", orgId));
    },

    getPlugin(orgId, pluginId) {
        return ApiService.get(`${URLS.plugins.replace(":orgId", orgId)}/${pluginId}`);
    },

    addAddonCredentials(orgId, pluginId, credentials) {
        const axiosOpts = {
            data: {
                pluginId,
                credentials,
            },
        };
        return ApiService.post(`${URLS.credentials.replace(":orgId", orgId)}`, axiosOpts);
    },

    editAddonCredentials(orgId, pluginId, credentials) {
        const axiosOpts = {
            data: {
                credentials,
            },
        };
        return ApiService.patch(
            `${URLS.credentials.replace(":orgId", orgId)}/${pluginId}`,
            axiosOpts,
        );
    },

    deleteCredentials(orgId, pluginId) {
        return ApiService.delete(`${URLS.credentials.replace(":orgId", orgId)}/${pluginId}`);
    },

    getPresets(orgId, searchOpts, pageNo, pageSize, archived, sortBy) {
        const axiosOpt = {
            params: {
                ...searchOpts,
                pageNo,
                pageSize,
                archived: archived,
                sort: sortBy ? sortBy : "presetName",
            },
        };
        return ApiService.get(`${URLS.presets}`.replace(":orgId", orgId), axiosOpt);
    },

    savePresets(orgId, presetName, transformation) {
        const axiosOpts = {
            data: {
                presetName,
                transformation,
            },
        };
        return ApiService.post(URLS.presets.replace(":orgId", orgId), axiosOpts);
    },

    updatePresets(orgId, presetName, updateOpts) {
        const axiosOpts = {
            data: updateOpts,
        };
        return ApiService.patch(
            URLS.presets.replace(":orgId", orgId) + `/${presetName}`,
            axiosOpts,
        );
    },

    archivePreset(orgId, presetName) {
        const axiosOpts = {
            data: {
                archived: true,
            },
        };
        return ApiService.patch(
            `${URLS.presets.replace(":orgId", orgId)}/${presetName}`,
            axiosOpts,
        );
    },

    getPreset(orgId, presetName) {
        return ApiService.get(`${URLS.presets.replace(":orgId", orgId)}/${presetName}`);
    },

    getFolder(path, name, orgId) {
        const axiosOpt = {
            params: {
                path,
                name,
            },
        };
        return ApiService.get(`${URLS.createFolder}`.replace(":orgId", orgId), axiosOpt);
    },

    getFolderIdByPath(orgId, path, name) {
        return ApiService.get(
            `${URLS.foldersUrl}`.replace(":orgId", orgId) + `?path=${path}&name=${name}`,
        );
    },

    getFile(orgId, fileId) {
        const axiosOpt = {};
        return ApiService.get(`${URLS.filesUrl.replace(":orgId", orgId)}/${fileId}`, axiosOpt);
    },

    getFileById(orgId, fileId) {
        return ApiService.get(`${URLS.filesUrl}/id/${fileId}`.replace(":orgId", orgId));
    },

    deletePreset(orgId, presetName) {
        return ApiService.delete(`${URLS.presets}/${presetName}`.replace(":orgId", orgId));
    },

    getAppliedTransformations(orgId, fileId, pageNo, pageSize) {
        const axiosOptions = {
            params: {
                fileId,
                pageNo,
                pageSize,
            },
        };

        return ApiService.get(URLS.transformationsUrl.replace(":orgId", orgId), axiosOptions);
    },
};

export default ExplorerService;

const KB_MULTIPLIER = 1024;
const getFilterProperties = (searchOpts) => {
    const sizeOptions = { kb: 1, mb: 2 };

    const filters = {
        ...searchOpts,
        minSize: "",
        maxSize: "",
        startDate: searchOpts.startDate,
        endDate: searchOpts.endDate,
        format: searchOpts.format,
    };

    let multiplier = Math.pow(KB_MULTIPLIER, sizeOptions[searchOpts.sizeFormat]);
    if (!isNaN(parseInt(searchOpts.minSize))) {
        filters.minSize = searchOpts.minSize ? searchOpts.minSize * multiplier : searchOpts.minSize;
    } else {
        filters.minSize = searchOpts.maxSize;
    }
    if (!isNaN(parseInt(searchOpts.maxSize))) {
        filters.maxSize = searchOpts.maxSize ? searchOpts.maxSize * multiplier : searchOpts.maxSize; // keep empty in case of no max defined
    } else {
        filters.maxSize = searchOpts.maxSize;
    }

    return filters;
};
