class MultiSelectScrollHandler {
    constructor() {
        this.animationFrameId = null;
        this.top = "0px";
    }

    adjustMultiSelectionPosition() {
        const elements = document.getElementsByClassName("multi-select-controll");
        if (elements?.length) {
            const multiSelectController = elements[0];
            multiSelectController.style.top = this.top;
        }
    }

    updateElementPosition(data) {
        if (this.animationFrameId !== null) {
            cancelAnimationFrame(this.animationFrameId);
        }
        this.animationFrameId = requestAnimationFrame(() => {
            const stickyBarHeight = 116; // height = 84px +16 px margin top and bottom
            const iframeTop = data.iframeTop;
            const iframeBottom = data.iframeBottom;
            const viewportHeight = data.viewportHeight;
            const iframeHeight = data.iframeHeight;
            const fixedBottom = iframeHeight - stickyBarHeight + "px";

            if (iframeBottom > viewportHeight) {
                this.top = viewportHeight - stickyBarHeight - iframeTop + "px";
            } else if (this.top !== fixedBottom) {
                this.top = fixedBottom;
            }
            this.adjustMultiSelectionPosition();
        });
    }
}

export class WidgetEventEmitter {
    constructor({ config }) {
        const origin = new URL(config.frameHost);
        this.allowedOrigins = [origin]; // The origin where messages are allowed to be sent
        this.initCompleted = config.initCompleted;
        this.openMlWithOptions = config.openMlWithOptions;
        this.mediaLibraryId = config.ml_id;
        this.multiSelectScrollHandler = new MultiSelectScrollHandler();

        this.adjustMultiSelectionPosition = () => {
            return this.multiSelectScrollHandler.adjustMultiSelectionPosition();
        };

        this.handleMessage = (event) => {
            if (this.allowedOrigins[0].origin == event.origin) {
                let message;
                try {
                    message = JSON.parse(event.data);
                } catch (error) {
                    console.error("Failed to parse incoming message data:", error);
                    return;
                }

                if (message.type == "ML_WIDGET_ADJUST_MULTISELECT") {
                    this.multiSelectScrollHandler.updateElementPosition(message.data);
                }
            }
        };

        window.addEventListener("message", this.handleMessage);
    }

    sendAssets(assets) {
        this._sendMessage("ML_WIDGET_INSERT_DATA", { ...assets });
    }

    removeListener() {
        window.removeEventListener("message", this.handleMessage);
    }

    /*
    *                sendIdentity: identity => {
                    postMessage('identity', { ...identity, mlId: mediaLibraryId });
                },
                sendUploadWidgetEvent: event => {
                    postMessage('upload', { ...event, mlId: mediaLibraryId });
                },
                sendDeletedItems: items => {
                    postMessage('delete', { ...items, mlId: mediaLibraryId });
                },

    */

    _sendMessage(type, data, target = this.allowedOrigins[0]) {
        if (!this.allowedOrigins.includes(target)) {
            throw new Error("[sendMessage] - target origin not allowed!");
        }
        try {
            console.debug(`[sendMessage]: posting message to: ${target}`);
            window.parent.postMessage(
                JSON.stringify({ type, mlId: this.mediaLibraryId, data }),
                target.origin,
            );
        } catch (error) {
            console.error(`[sendMessage]: failed to post message to target: ${target}`, error);
        }
    }
}
